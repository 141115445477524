const setAlignFullScrollBarWidth = function () {
    function getScrollbarWidth() {

        // Creating invisible container
        const outer = document.createElement('div');
        outer.style.visibility = 'hidden';
        outer.style.overflow = 'scroll'; // forcing scrollbar to appear
        outer.style.msOverflowStyle = 'scrollbar'; // needed for WinJS apps
        document.body.appendChild(outer);

        // Creating inner element and placing it in the container
        const inner = document.createElement('div');
        outer.appendChild(inner);

        // Calculating difference between container's full width and the child width
        const scrollbarWidth = (outer.offsetWidth - inner.offsetWidth);

        // Removing temporary elements from the DOM
        outer.parentNode.removeChild(outer);

        return scrollbarWidth;

    }

    // if (typeof gekasAcf !== 'undefined') return false;

    // let body = document.body;

    // console.log(body.classList);


    // if (body.classList.contains('itc-takeover-visible')) {
    //     return;
    // }

    let element = document.querySelectorAll('.alignfull');

    element.forEach((el) => {
        let scroll_bar_width = getScrollbarWidth();
        let scroll_bar_half_width = scroll_bar_width / 2;
        let element_style = getComputedStyle(el);
        let css_var_scroll_bar_half_width = element_style.getPropertyValue('--scroll-bar-half-width');
        if (scroll_bar_half_width + 'px' !== css_var_scroll_bar_half_width) {
            el.style.setProperty('--scroll-bar-half-width', scroll_bar_half_width + 'px');
        }
    });
}


export { setAlignFullScrollBarWidth };
