import { Swiper } from 'swiper';
import { Thumbs, Autoplay, Keyboard, Navigation, Pagination, Scrollbar } from 'swiper/modules';
// import Swiper styles
import 'swiper/css';
import 'swiper/css/thumbs';


/* Swiperjs for booking */
$(window).on('searchBookingFinish', function () {
    const myCustomSlider = document.querySelectorAll('.swiper-booking-container');

    for (var i = 0; i < myCustomSlider.length; i++) {

        myCustomSlider[i].querySelector('.swiper-booking').classList.add('slider-' + i);

        new Swiper('.slider-' + i, {
            /* Options */
            modules: [Navigation],
            loop: true,
            spaceBetween: 10,
            navigation: {
                nextEl: ".swiper-button-next",
                prevEl: ".swiper-button-prev",
            },
        });
    }

});
