var gmarkers1 = [];
var markers1 = [];
var infowindow = '';
var iconBase = '/wp-content/themes/gekas-new/assets/frontend/media/';
var center;
var map
var markers1 = typeof cities !== 'undefined' ? cities : [];

let MarkerInit
let LatLngInit
let LatLngBoundsInit


// Start Google maps
async function initMap() {

    if (!document.getElementById('buss-travel-map')) {
        return;
    }

    const { Map, InfoWindow } = await google.maps.importLibrary("maps");
    const { LatLng, LatLngBounds } = await google.maps.importLibrary("core")
    const { Marker } = await google.maps.importLibrary("marker")

    MarkerInit = Marker;
    LatLngInit = LatLng;
    LatLngBoundsInit = LatLngBounds;


    center = new LatLngInit(57.134223, 12.714334);
    var mapOptions = {
        zoom: 7,
        center: center
    };

    infowindow = new InfoWindow({
        content: ''
    });

    map = new Map(document.getElementById('buss-travel-map'), mapOptions);

    infowindow.addListener('closeclick', function () {
        do_bus_search('');
    });

    for (var i = 0; i < markers1.length; i++) {
        addMarker(markers1[i]);
    }

    var marker_gekas = new MarkerInit({
        title: 'Gekås Ullared',
        position: new LatLngInit(57.134223, 12.714334),
        icon: iconBase + 'map-icon-gekas.png',
        category: '',
        zIndex: 99999,
        map: map
    });

}


// Add the markers
function addMarker(marker) {

    if (marker.lat == '--' || marker.lng == '--') {
        return false;
    }

    var category = marker.category;
    var title = marker.name;
    var pos = new LatLngInit(marker.lat, marker.lng);
    var content = marker.name;

    var marker1 = new MarkerInit({
        title: title,
        position: pos,
        icon: iconBase + 'map-icon-buss.png',
        category: category,
        map: map
    });

    gmarkers1.push(marker1);

    // Marker click listener
    marker1.addListener('click', (function (marker1, content) {
        return function () {
            infowindow.setContent(content);
            infowindow.open(map, marker1);
            map.panTo(this.getPosition());
            map.setZoom(10);
            do_bus_search(category);
        }
    })(marker1, content));

}


// Filter markers on select
function filterMarkers(category) {

    console.log('filterMarkers', category);

    var category_length = category.length;
    var not_selected = ($.inArray('not_selected', category)) ? false : true;

    var bounds = new LatLngBoundsInit();

    for (var i = 0; i < markers1.length; i++) {
        var marker = gmarkers1[i];
        // If is same category or category not picked
        var marker_lower = marker.category.toLowerCase();

        if (not_selected || category.length === 0 || $.inArray(marker_lower, category) !== -1) {
            marker.setVisible(true);

            if (category_length > 1) {
                bounds.extend(marker.getPosition());
            }
            else {
                map.panTo(marker.getPosition());
            }
        } else {
            // Categories don't match
            marker.setVisible(false);
        }
    }

    if (not_selected) {
        map.setZoom(7);
    }
    else if (category_length > 1) {
        map.fitBounds(bounds);
    }
    else {
        map.setZoom(10);
    }
}


// Do the page filtering
function do_bus_search(val) {
    if (val == '') {
        filterMarkers(['not_selected']);
        $('#bus-company-list').find('li').show();
    } else {
        $('#bus-company-list li').hide();
        var val_lower = val.toLowerCase();

        $("#bus-company-list li[data-name*='" + val_lower + "']").show();
        $("#bus-company-list li[data-district*='" + val_lower + "']").show();
        $("#bus-company-list li[data-cities*='" + val_lower + "']").show();

        var found = $("#bus-company-list li[data-name*='" + val_lower + "']").data('district');

        if (found != null && found.length > 0) {
            var found_array = [];
            var $found = $("#bus-company-list li[data-name*='" + val_lower + "']");
            var found_cities = $found.data('cities');
            var found_district = $found.data('district');

            if (found_cities.length > 0) {
                found_array = found_cities.split(',');
            }

            if (found_district != null && $.inArray(found_district, found_array) === -1) {
                found_array.push(found_district);
            }

            filterMarkers(found_array);
        }
        else {
            filterMarkers([val_lower]);
        }
    }
}


$(document).ready(function () {


    $('#buss-search-input').autocomplete({
        minChars: 2,
        appendTo: '#autocomplete-container',
        source: function (term, suggest) {


            term = term.term.toLowerCase();
            var choices = cities_short;
            var choices_names = names_short;
            var matches = [];

            for (var i = 0; i < choices.length; i++)
                if (~choices[i].toLowerCase().indexOf(term)) matches.push(choices[i]);
            for (var i = 0; i < choices_names.length; i++)
                if (~choices_names[i].toLowerCase().indexOf(term)) matches.push(choices_names[i]);
            suggest(matches);
        },
        onSelect: function (e, term, item) {
            do_bus_search(term);
        }
    });


    $('.btn-toggle-accordions-holder').on('click', function () {
        $('.accordions-holder-container').toggleClass('is-active');
        return false;
    });


    $('.btn-search-busses').on('click', function () {
        var val = $('#buss-search-input').val();
        do_bus_search(val);
        return false;
    });

    $(document).keypress(function (e) {
        if (e.which == 13) {
            var val = $('#buss-search-input').val();
            $('#buss-search-input').blur();
            do_bus_search(val);
            return false;
        }
    });

    $('#buss-search-input').on('change', function () {
        if ($(this).val() == '') {
            do_bus_search('');
        }
    });


    initMap();

});
