document.addEventListener("DOMContentLoaded", () => {
  const btn = document.querySelector("#search-btn");
  const close = document.querySelector(".search-close");

  const wpAdminBar = document.querySelector("#wpadminbar");
  const overlay = document.querySelector(".overlay-search");

  if (document.body.contains(wpAdminBar)) {
    overlay.style.top = "32px";
  }

  btn.addEventListener("click", () => {
    overlay.classList.toggle("active");
    document.body.style.overflow = "hidden";
  });

  close.addEventListener("click", () => {
    overlay.classList.remove("active");
    document.body.style.overflow = "auto";
  });

  // close on escape key
  document.addEventListener("keydown", (e) => {
    if (e.key === "Escape") {
      overlay.classList.remove("active");
      document.body.style.overflow = "auto";
    }
  });

  // const searchField = document.querySelector(".search-field");
  // const autocompleteList = document.getElementById("rlvlive");
  // let currentFocus = -1;
  // let suggestions = [];

  // function removeActive() {
  //   const items = autocompleteList.getElementsByTagName("div");
  //   Array.from(items).forEach((item) =>
  //     item.classList.remove("autocomplete-active"),
  //   );
  // }

  // // Function to add active class
  // function addActive(index) {
  //   const items = autocompleteList.getElementsByTagName("div");
  //   if (!items.length) return false;
  //   removeActive();
  //   if (index >= items.length) currentFocus = 0;
  //   if (index < 0) currentFocus = items.length - 1;
  //   items[currentFocus].classList.add("autocomplete-active");
  // }

  // // Function to show suggestions
  // function showSuggestions(suggestions) {
  //   autocompleteList.innerHTML = "";
  //   if (!suggestions.length) return;

  //   suggestions.forEach((item, index) => {
  //     const suggestionItem = document.createElement("div");
  //     suggestionItem.textContent = item.title;
  //     suggestionItem.setAttribute("data-index", index);
  //     suggestionItem.setAttribute("data-url", item.url);

  //     // On suggestion click
  //     suggestionItem.addEventListener("click", () => {
  //       searchField.value = item.title;
  //       autocompleteList.innerHTML = ""; // Close the list
  //       window.location.href = item.url;
  //     });

  //     autocompleteList.classList.add("border");
  //     autocompleteList.appendChild(suggestionItem);
  //   });
  // }

  // // // AJAX call to fetch suggestions from WP backend
  // // function fetchSuggestions(inputValue) {
  // //   fetch(`/wp-admin/admin-ajax.php?action=site_search&search=${inputValue}`, {
  // //     method: "GET",
  // //   })
  // //     .then((response) => response.json())
  // //     .then((data) => {
  // //       showSuggestions(data); // Display suggestions
  // //     });
  // // }

  // // Event listener for input changes
  // // searchField.addEventListener("input", function () {
  // //   const inputValue = this.value;
  // //   if (!inputValue) {
  // //     autocompleteList.innerHTML = "";
  // //     return;
  // //   }
  // // });

  // // Keyboard navigation
  // searchField.addEventListener("keydown", function (e) {
  //   const items = autocompleteList.getElementsByTagName("div");

  //   if (e.key === "ArrowDown") {
  //     currentFocus++;
  //     searchField.value = items[currentFocus].textContent;
  //     addActive(currentFocus);
  //   } else if (e.key === "ArrowUp") {
  //     currentFocus--;
  //     searchField.value = items[currentFocus].textContent;
  //     addActive(currentFocus);
  //   } else if (e.key === "Enter") {
  //     e.preventDefault();
  //     if (currentFocus > -1) {
  //       if (items[currentFocus]) {
  //         window.location.href = items[currentFocus].getAttribute("data-url");
  //         autocompleteList.innerHTML = ""; // Close the list
  //       }
  //     }
  //   }
  // });

  // //Close the dropdown when clicking outside
  // document.addEventListener("click", function (e) {
  //   if (e.target !== searchField) {
  //     autocompleteList.innerHTML = "";
  //   }
  // });
});
