import MD5 from "crypto-js/md5";

var itc_takeover = {
    'takeover_id': false,
    'container': false,
    'target_url': '',
    'data': false,
    'click_active': false,
    'init': function (args) {

        this.takeover_id = args.takeover_id;
        if (args.url == window.location.pathname || ((new Date().getTime() - (parseInt(this.get_cookie(this.takeover_id)) || 0)) < (86400 * 1000))) return false;
        this.data = args.data;
        this.data.tablet = args.data.desktop;
        this.target_url = args.url;

        // var url = window.location.href;
        var url = window.location.pathname;

        var show = false;
        var state = ['show', 'hide'];

        for (var k in state) {
            var s = state[k];
            var ws = (this.data.visibility[s].length > 0) ? this.data.visibility[s].split(',') : '';

            for (var i = 0; i < ws.length; i++) {
                if (ws[i].trim() == '/') {
                    if (window.location.pathname == '/') {
                        show = (s == 'show');
                        break;
                    }
                }
                //else if (url.indexOf(ws[i].trim()) != -1)
                else if (url == ws[i].trim()) {
                    show = (s == 'show');
                    break;
                }
            }
            if (!show) break;
        }

        if (!show) return false;

        var el = document.createElement('link');
        el.rel = 'stylesheet';
        el.type = 'text/css';
        el.href = '/wp-content/themes/gekas-new/assets/frontend/js/gekas/takeover/takeover.css?30';
        document.body.appendChild(el);

        this.show();
    },
    'set_cookie': function (id, value, expires) {
        var d = new Date();
        d.setTime(d.getTime() + expires);
        document.cookie = id + "=" + value + "; expires=" + d.toUTCString() + ";path=/";
    },
    'get_cookie': function (name) {
        if (document.cookie.indexOf(name + '=') == -1) return false;
        var parts = ("; " + document.cookie).split("; " + name + "=");
        if (parts.length == 2) var value = parts.pop().split(";").shift();
        return value;
    },
    'show': function (f) {
        document.body.classList.add('itc-takeover-visible');
        var self = this;
        var d = this.data;
        var layout = 'image';
        var ar = ['title', 'text', 'label'];
        for (var k in ar) {
            var r = ar[k];
            if (r in d && d[r] != '') layout = 'image_text';
        }

        var img_el = document.createElement('img');
        img_el.src = this.get_image();
        img_el.style.display = 'none';
        document.body.appendChild(img_el);

        img_el.onload = function () {
            this.remove();
            var output = '<div class="itc-takeover-banners">';
            output += '<span class="close" onclick="itc_takeover.close()"></span>';
            output += '<div class="' + layout + ' banner-container itc-takeover-fadeIn ' + d.align + '" onclick="itc_takeover.close(1)">';
            output += '<div class="image-holder">';
            output += '<img src="' + img_el.src + '">';
            output += '</div>';
            if (layout == 'image_text') {
                output += '<style>#itc-takeover .banner-container {' + ((d.bgcolor) ? 'background:' + d.bgcolor + '!important;' : '') + '}';
                output += '#itc-takeover .text-holder h2,#itc-takeover .text-holder p {' + ((d.color) ? 'color:' + d.color + '!important;' : '') + '}</style>';
                output += '<div class="text-holder">';
                output += (d.title) ? '<h2>' + d.title + '</h2>' : '';
                output += (d.text) ? '<p>' + d.text.replace("\n", "<br>") + '</p>' : '';
                output += (d.label) ? '<p><a href="' + self.target_url + '" class="btn gk-button">' + d.label + '</a></p>' : '';
                output += '</div>';
            }
            output += '</div>';
            output += '</div>';

            var el = document.createElement('div');
            el.id = 'itc-takeover';
            el.innerHTML = output;
            document.body.appendChild(el);


            self.container = el;
            setTimeout(function () {
                self.container.classList.add('itc-takeover-fadeIn');
                window.addEventListener('resize', self.resize.bind(self));
                self.resize();
            }, 50);

            self.container.addEventListener('click', itc_takeover.close);

            setTimeout(function () {
                self.click_active = true;
                self.resize();
                self.set_cookie(self.takeover_id, new Date().getTime(), (720 * 86400 * 1000));
            }, 1000);
        };
    },
    'get_image': function () {
        var w = window.innerWidth;
        var h = window.innerHeight;
        if (w > 768) return this.data.desktop;
        if (w > 499 && h > 850) return this.data.tablet;
        return this.data.mobile;
    },
    'resize': function () {
        var el = this.container;
        if (!el) return;
        //el.style.alignItems = (el.querySelector('.banner-container').getBoundingClientRect().height > window.innerHeight) ? 'baseline' : 'center';
        if ((el.querySelector('.banner-container').getBoundingClientRect().height > window.innerHeight)) {
            el.style.alignItems = 'baseline';
            if (window.innerWidth < 499) el.querySelector('.close').style.top = '0px';
        }
        var rimg = this.get_image();
        var cimg;
        if ((cimg = el.querySelector('.image-holder img')) && cimg.src != rimg) cimg.src = rimg;
    },
    'close': function (goto_url) {
        var it = itc_takeover, d = new Date();
        if (!it.click_active && goto_url != 1) return false;
        if (typeof goto_url === 'number') window.location.href = it.target_url;

        it.container.classList.add('itc-takeover-fadeOut');
        setTimeout(function () {
            it.container.remove();
            document.body.classList.remove('itc-takeover-visible');
        }, 300);
        window.removeEventListener('resize', it.resize);
    }
}

jQuery(document).ready(function ($) {
    if (typeof gekasAcf !== 'undefined') {
        itc_takeover.init({
            'takeover_id': MD5(gekasAcf.takeoverUrl).toString(),
            'url': gekasAcf.takeoverUrl,
            'data': gekasAcf.takeover
        });
    }

});
